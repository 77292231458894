<template>
  <div class="about_us">
    <div class="title">
      <div class="title-content">公司简介</div>
    </div>
    <div class="presentation">
      <div class="presentation-content">
        <div class="presentation-left">
          <h2 class="presentation-title">北京世纪中微科技有限公司</h2>
          <p>
            2017年，北京世纪中微科技有限公司正式成立。世纪中微，作为一家全球跨境企业服务综合运营商，世纪中微致力于为各大中小企业提供专业的、有效的、国际化的企业服务。
          </p>
        </div>
        <div class="presentation-right">
          <img src="../../public/img/about_us-1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="map">
      <div class="map-content">
        <div class="map-img">
          <img src="../../public/img/about_us-2.png" alt="" />
        </div>
        <div class="map-info">
          <h4 class="map-title">全国的资源全产业链布局</h4>
          <p>
            一览从北京出发，现已中国国内在深圳、广州、武汉、长沙、西安、成都、苏州等主要城市开设分公司。并在西雅图、悉尼、罗马等地设立办事处。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
};
</script>

<style lang="scss" scoped>
@mixin layout {
  width: 1600px;
  height: 100%;
  margin: 0 auto;
}

.about_us {
  padding-bottom: 100px;
}

.title {
  width: 100%;
  height: 120px;
}

.title-content {
  @include layout;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
}

.presentation {
  width: 100%;
  height: 498px;
}

.presentation-content {
  @include layout;
  display: flex;
}

.presentation-left {
  background-color: #1e7d9d;
  width: 40%;
  padding: 110px 40px 0;
  color: white;

  p {
    margin-top: 52px;
    line-height: 40px;
  }
}

.presentation-title {
  font-size: 30px;
}

.presentation-right {
  width: 60%;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.map {
  margin-top: 100px;
}

.map-content {
  @include layout;
  display: flex;
}

.map-img {
  width: 50%;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.map-info {
  width: 50%;
  color: #666666;
  padding: 80px 150px 0;
  font-size: 18px;

  p {
    line-height: 40px;
  }
}

.map-title {
  font-weight: 400;
  //font-size: 16px;
  border-bottom: 1px solid #797979;
  height: 40px;
}
</style>
